.thc__gallery h2 {
  font-size: 3rem;
  color: #3ec70b;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(150px, auto);
  gap: 20px;
  opacity: 0;
}

.thc__gallery-category {
  margin: 40px 0 20px;
  font-size: 2rem;
  text-align: center;
  color: #3ec70b;
}

.gallery-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
  .gallery-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

.gallery-container.slide-in {
  animation: slideIn 0.8s ease-out forwards;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.gallery-container.slide-in img {
  animation: slideIn 0.8s ease-out forwards;
}

.gallery-container.slide-in img:nth-child(1) {
  animation-delay: 0s;
}

.gallery-container.slide-in img:nth-child(2) {
  animation-delay: 0.2s;
}

.gallery-container.slide-in img:nth-child(3) {
  animation-delay: 0.4s;
}

.gallery-container.slide-in img:nth-child(4) {
  animation-delay: 0.6s;
}

.gallery-container.slide-in img:nth-child(5) {
  animation-delay: 0.8s;
}
.ant-modal-content {
  background-color: var(--color-bg) !important;
}

.ant-modal-content .ant-modal-close {
  top: 21px !important;
  right: 5px !important;
  color: white !important;
}

.ant-modal-content .ant-modal-close .ant-modal-close-x {
  font-size: 30px !important;
}
