.thc__paymentFailure {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.thc__paymentFailure h2 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: red;
}

strong {
  color: var(--color-text);
}
/* Style the paragraph text */
.thc__paymentFailure p {
  font-size: 16px;
  margin-bottom: 15px;
}
