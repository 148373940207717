.thc__marqueeElement-wrapper {
    /* min-width: 300px; */
    max-width: 800px;
}

.thc__marqueeElement-container {
    border: 1px solid #848383;
    padding: 15px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: var(--color-footer);
    margin: 0 20px;
}

.thc__marqueeElement-container img {
    width: 40px;
    height: 40px;
    /* flex: 1; */
}

.thc__marqueeElement-container p {
    margin-left: 15px;
    font-size: 1.2rem;
}