.thc__paymentPage-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.thc__paymentPage-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}
.thc_paymentPage-left {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 0.5;
}
.thc_paymentPage-left__title {
  display: flex;
  margin-right: 30px;
}
.thc_courseThumbnail video {
  width: 200px;
  object-fit: contain;
  border-radius: 10px;
}

.thc_paymentPage-left__title h3 {
  font-size: 2.5rem;
  margin: 0 20px;
}

.thc__payment-features ul h3 {
  font-size: 1.5rem;
  color: #3ec70b;
}

.thc__payment-features ul {
  margin: 20px 0;
}

.thc__payment-features ul li {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  margin-left: 30px;
  font-size: 1.2rem;
}

.thc_payment-userDetails_form-control {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.thc_payment-userDetails_form-control input {
  display: inline-block;
  width: 60%;
  padding: 10px;
  border-radius: 10px;
  outline: none;
  background-color: transparent;
  border: 1px solid #383838;
}

.thc__paymentPage-right {
  border: 1px solid #383838;
  padding: 30px;
  border-radius: 20px;
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 0.5;
  min-width: 400px;
}

.thc__paymentPage-right h3 {
  font-size: 2rem;
}

.thc__paymentPage-right .details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.thc__paymentPage-right .details p {
  font-size: 1.3rem;
}

.payment_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.payment_btn button {
  border: 1px solid var(--color-btns);
  padding: 20px 50px;
  border-radius: 50px;
  outline: none;
  text-transform: uppercase;
}

.payment_btn button:hover {
  background-color: var(--color-btns);
  color: white;
}
