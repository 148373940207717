.thc__pricing-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  gap: 50px;
  overflow-x: hidden;
}

.slider {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 1000px;
  height: 600px;
  border-radius: 10px;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}


.thc__pricing-container {
  display: flex;
  padding: 30px;
  justify-content: space-between;
  width: 90%;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
.thc__pricing-left {
  flex: 0.7;
}

.thc__pricing-left h2 {
  font-size: 3rem;
  margin-bottom: 10px;
}
.thc__pricing-features ul h3 {
  font-size: 1.5rem;
  color: #3ec70b;
}

.thc__pricing-features ul {
  margin: 20px 0;
}

.thc__pricing-features ul li {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  margin-left: 30px;
  font-size: 1.2rem;
}

.thc__pricing-right {
  background-color: var(--color-footer);
  border-radius: 20px;
  flex: 0.3;
  /* width: 30%; */
}

.thc__pricing-salescard {
  display: flex;
  flex-direction: column;
}

.thc__pricing-details__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.actual-price,
.discount-price {
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actual-price {
  position: relative;
  overflow: hidden;
}

.actual-price::before {
  content: "";
  position: absolute;
  width: 100%;
  z-index: 1;
  height: 2px;
  background-color: #fff;
}

.discount {
  background-color: #f57b01;
  padding: 3px 8px;
  margin: 5px 0;
  font-size: 14px;
  border-radius: 50px;
  display: inline-block;
}

.thc__pricing-thumbnail {
  height: 200px;
  overflow: hidden;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thc__pricing-thumbnail video {
  height: 100%;
  border-radius: 20px;
}

.thc__pricing-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}

.thc__pricing-joinus {
  background-color: var(--color-btns);
  text-align: center;
  border-radius: 50px;
  padding: 10px 20px;
}
.thc__pricing-timeline {
  background-color: white;
  text-align: center;
  color: black;
  border-radius: 50px;
  padding: 10px 20px;
}

.thc__pricing-info__footer {
  border-top: 3px solid black;
  margin-top: 30px;
  padding: 20px;
}

.thc__pricing-info__footer h2 {
  font-size: 20px;
}

.thc__pricing-info__footer ol {
  display: flex;
  list-style-type: disc;
  flex-direction: column;
  gap: 10px;
  margin-left: 30px;
}

/* .marquee-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
} */

@media screen and (max-width: 568px) {
  .thc__pricing-container {
    flex-direction: column-reverse;
  }

  .thc__pricing-details {
    text-align: center;
  }

  .thc__pricing-left h2 {
    margin-top: 10px;
    font-size: 2.5rem;
    text-align: center;
  }
}
