.thc__passwordreset-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #ddd;
}

.thc__passwordreset-container {
    background-color: white;
    padding: 30px;
    /* height: 300px; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    width: 400px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.thc__passwordreset-header {
    text-align: center;
}

.thc__passwordreset-header h2 {
    color: var(--color-text);
}

.thc__passwordreset-container button {
    margin-top: 20px;
    float: right;
}