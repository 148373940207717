.gradient__text {

    /* ff 3.6+ */
    background: -moz-linear-gradient(var(--gradient-text));

    /* safari 5.1+,chrome 10+ */
    background: -webkit-linear-gradient(var(--gradient-text));

    /* opera 11.10+ */
    background: -o-linear-gradient(var(--gradient-text));

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8AEE82', endColorstr='#00FF40', GradientType=0);

    /* ie 10+ */
    background: -ms-linear-gradient(var(--gradient-text));

    /* global 94%+ browsers support */
    background: linear-gradient(var(--gradient-text));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}


.thc__header {
    display: flex;
    overflow: hidden;
    height: 70vh;
}

.thc__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;
}


.thc__header-content span {
    font-size: 20px;
    color: gray;
}

.thc__header-content h1 {
    font-weight: 800;
    font-size: 4.6rem;
    letter-spacing: 5px;
}

.thc__header-content p {
    font-style: normal;
    font-size: 18px;
    line-height: 27px;
    margin-top: 1.5rem;
    color: white;
}

.thc__header-img {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .thc__header-img img {
    width: 100%;
    height: 100%;
} */


@media screen and (max-width:1050px) {
    .thc__header {
        flex-direction: column;
    }

    .thc__header-content {
        margin: 0 0 3rem;
    }

}


@media screen and (max-width:650px) {
    .thc__header-content h1 {
        font-size: 4rem;
        line-height: 60px;
    }

    .thc__header-content p {
        font-size: 1rem;
        line-height: 24px;
    }

    .thc__header-content {
        margin: 0 0 3rem;
        text-align: center;
    }
}