.thc__forgotPassword-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #ddd;
}

.thc__forgotPassword-container {
    background-color: white;
    padding: 30px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.thc__forgotPassword-container h1 {
    color: rgb(0, 182, 0);
}

.thc__forgotPassword-container p {
    font-size: 15px;
}

.thc__forgotPassword-container div {
    width: 100%;
}

.thc__forgotPassword-container h2 {
    color: var(--color-text);
}

.thc__forgotPassword-container button {
    margin-top: 10px;
}