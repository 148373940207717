.type-writer {
    font-size: 5rem;
}

.type-writer span {
    color: #7DCE13 !important;
    margin-left: 10px;
}

.end-cursor:after {
    content: '|';
}

.end-cursor.blinking:after {
    animation: blink 1s step-start infinite;
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}