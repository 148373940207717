.thc__loader-container {
    /* background-color: #ddd; */
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    margin: 0;
}

.thc__loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.thc__loader div {
    background-color: #02003b;
    width: 5px;
    height: 40px;
    border-radius: 2.5px;
    margin: 2px;
    animation: oddload 2s cubic-bezier(0.19, 0.57, 0.3, 0.98) infinite;
}

.thc__loader div:nth-child(2) {
    animation-delay: 0.1s;
}

.thc__loader div:nth-child(3) {
    animation-delay: 0.2s;
}

.thc__loader div:nth-child(4) {
    animation-delay: 0.3s;
}

.thc__loader div:nth-child(5) {
    animation-delay: 0.4s;
}

@keyframes oddload {
    0% {
        transform: scaley(1);
        opacity: 0.5;
    }

    50% {
        transform: scaley(0.2);
        opacity: 1;
    }

    100% {
        transform: scaley(1);
        opacity: 0.5;
    }
}