.thc__verifyEmail-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.thc__verifyEmail-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}


.thc__verifyEmail-container>* {
    color: white;
}