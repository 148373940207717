@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900;1000&display=swap');

:root {

  --gradient-text: linear-gradient(89.97deg, #49FF00, #54E346 102.67%);
  --gradient-bar: linear-gradient(90deg, rgba(6, 255, 0, 1) 0%, rgba(73, 255, 0, 1) 100%);

  --color-bg: #040c18;
  --color-footer: #031b34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #F4742F;
  --color-btns: #7DCE13;

}

@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
}

/* background of the scrollbar except button or resizer */

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}