.thc__courseInfo-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



.thc__courseInfo-wrapper h2 {
    color: white;
}

.month-select select {
    width: 200px;
    padding: 10px;
    border-radius: 8px;
    background-color: var(--color-footer);
    color: #fff;
    font-family: inherit;
}

.thc__courseInfo-container {
    display: flex;
    flex-direction: column;
    margin: 40px 0;
    position: relative;
    width: 900px;
    color: white;
}


.thc__courseInfo-container::after {
    content: "";
    background-color: var(--color-btns);
    position: absolute;
    left: calc(50% - 2px);
    width: 4px;
    height: 100%;
}



@media screen and (max-width:768px) {
    .thc__courseInfo-container{
        width: 100%;
    }
}