.thc__testimonials {
  background-image: url("https://ik.imagekit.io/fq9vykvp2/v617batch2-bb-01-technology.jpg?updatedAt=1687023022297");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  /* object-fit: contain; */
  background-color: var(--color-footer);
}

.thc__testimonials-heading h2 {
  background: var(--gradient-text);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3rem;
  line-height: 45px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 600;
}
.thc__testimonials-center {
  height: 100%;
  overflow: hidden;
  /* padding: 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.thc__testimonials-center video {
  height: 100%;
  width: 80%;
  border-radius: 20px;
  /* background-color: transparent; */
  /* background: rgb(2, 0, 36); */
  backdrop-filter: blur(5px);
  /* background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(4, 4, 61, 1) 61%
  ); */
}

.carouselInner video::-webkit-media-controls-fullscreen-button {
  display: none;
}

.thc__testimonials-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
}

.thc__testimonials-left {
  height: 100%;
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
  padding: 20px;
}

.thc__testimonials-center {
  flex: 0.8;
  height: 100%;
}

.thc__testimonials-right {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 20px;
  cursor: pointer;
}
