@import "https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900;1000&display=swap";
@import "https://fonts.googleapis.com/css?family=Dosis:300,400,500";
:root {
  --gradient-text: linear-gradient(89.97deg, #49ff00, #54e346 102.67%);
  --gradient-bar: linear-gradient(90deg, #06ff00 0%, #49ff00 100%);
  --color-bg: #040c18;
  --color-footer: #031b34;
  --color-blog: #042c54;
  --color-text: #81afdd;
  --color-subtext: #f4742f;
  --color-btns: #7dce13;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.-top-1 {
  top: -.25rem;
}

.bottom-4 {
  bottom: 1rem;
}

.right-0 {
  right: 0;
}

.right-1 {
  right: .25rem;
}

.right-2 {
  right: .5rem;
}

.right-4 {
  right: 1rem;
}

.right-5 {
  right: 1.25rem;
}

.top-0 {
  top: 0;
}

.top-16 {
  top: 4rem;
}

.top-2 {
  top: .5rem;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.float-right {
  float: right;
}

.-m-6 {
  margin: -1.5rem;
}

.m-0 {
  margin: 0;
}

.m-2 {
  margin: .5rem;
}

.m-3 {
  margin: .75rem;
}

.m-4 {
  margin: 1rem;
}

.m-5 {
  margin: 1.25rem;
}

.-mx-3 {
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

.h-1\/2 {
  height: 50%;
}

.h-10 {
  height: 2.5rem;
}

.h-2 {
  height: .5rem;
}

.h-3\/4 {
  height: 75%;
}

.h-4 {
  height: 1rem;
}

.h-\[100\%\] {
  height: 100%;
}

.h-\[100px\] {
  height: 100px;
}

.h-\[120px\] {
  height: 120px;
}

.h-\[500px\] {
  height: 500px;
}

.h-\[98vh\] {
  height: 98vh;
}

.h-screen {
  height: 100vh;
}

.min-h-screen {
  min-height: 100vh;
}

.w-0 {
  width: 0;
}

.w-1\/2 {
  width: 50%;
}

.w-10 {
  width: 2.5rem;
}

.w-2 {
  width: .5rem;
}

.w-4 {
  width: 1rem;
}

.w-4\/5 {
  width: 80%;
}

.w-400 {
  width: 400px;
}

.w-72 {
  width: 18rem;
}

.w-96 {
  width: 24rem;
}

.w-\[100\%\] {
  width: 100%;
}

.w-\[100px\] {
  width: 100px;
}

.w-\[300px\] {
  width: 300px;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-0 {
  min-width: 0;
}

.max-w-\[300px\] {
  max-width: 300px;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-md {
  max-width: 28rem;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.list-inside {
  list-style-position: inside;
}

.list-disc {
  list-style-type: disc;
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-1 {
  gap: .25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-b, .border-b-1 {
  border-bottom-width: 1px;
}

.border-t-1 {
  border-top-width: 1px;
}

.border-t-4 {
  border-top-width: 4px;
}

.border-solid {
  border-style: solid;
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.border-color {
  border-color: #0000001a;
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity));
}

.border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgb(21 128 61 / var(--tw-border-opacity));
}

.border-neutral-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 212 / var(--tw-border-opacity));
}

.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.border-red-700 {
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity));
}

.border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgb(234 179 8 / var(--tw-border-opacity));
}

.border-b-gray-700 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(55 65 81 / var(--tw-border-opacity));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.bg-blue-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(23 37 84 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-half-transparent {
  background-color: #00000080;
}

.bg-main-bg {
  --tw-bg-opacity: 1;
  background-color: rgb(250 251 251 / var(--tw-bg-opacity));
}

.bg-main-navbar-bg {
  --tw-bg-opacity: 1;
  background-color: rgb(32 35 42 / var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.bg-teal-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(45 212 191 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-clip-padding {
  background-clip: padding-box;
}

.p-1 {
  padding: .25rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-\[0\.32rem\] {
  padding-top: .32rem;
  padding-bottom: .32rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-2\.5 {
  padding-bottom: .625rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-2\.5 {
  padding-top: .625rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-9 {
  padding-top: 2.25rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-14 {
  font-size: 14px;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.leading-8 {
  line-height: 2rem;
}

.leading-\[2\.15\] {
  line-height: 2.15;
}

.leading-normal {
  line-height: 1.5;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-50 {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity));
}

.text-lime-500 {
  --tw-text-opacity: 1;
  color: rgb(132 204 22 / var(--tw-text-opacity));
}

.text-neutral-700 {
  --tw-text-opacity: 1;
  color: rgb(64 64 64 / var(--tw-text-opacity));
}

.text-neutral-800 {
  --tw-text-opacity: 1;
  color: rgb(38 38 38 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.text-slate-900 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_4px_9px_-4px_\#3b71ca\] {
  --tw-shadow: 0 4px 9px -4px #3b71ca;
  --tw-shadow-colored: 0 4px 9px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-shadow {
  transition-property: box-shadow;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-150 {
  transition-duration: .15s;
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #0000;
}

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
}

::-webkit-scrollbar-button {
  display: none;
}

.file\:-mx-3::-webkit-file-upload-button {
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.file\:-mx-3::file-selector-button {
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.file\:-my-\[0\.32rem\]::-webkit-file-upload-button {
  margin-top: -.32rem;
  margin-bottom: -.32rem;
}

.file\:-my-\[0\.32rem\]::file-selector-button {
  margin-top: -.32rem;
  margin-bottom: -.32rem;
}

.file\:cursor-pointer::-webkit-file-upload-button {
  cursor: pointer;
}

.file\:cursor-pointer::file-selector-button {
  cursor: pointer;
}

.file\:overflow-hidden::-webkit-file-upload-button {
  overflow: hidden;
}

.file\:overflow-hidden::file-selector-button {
  overflow: hidden;
}

.file\:rounded-none::-webkit-file-upload-button {
  border-radius: 0;
}

.file\:rounded-none::file-selector-button {
  border-radius: 0;
}

.file\:border-0::-webkit-file-upload-button {
  border-width: 0;
}

.file\:border-0::file-selector-button {
  border-width: 0;
}

.file\:border-solid::-webkit-file-upload-button {
  border-style: solid;
}

.file\:border-solid::file-selector-button {
  border-style: solid;
}

.file\:border-inherit::-webkit-file-upload-button {
  border-color: inherit;
}

.file\:border-inherit::file-selector-button {
  border-color: inherit;
}

.file\:bg-neutral-100::-webkit-file-upload-button {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.file\:bg-neutral-100::file-selector-button {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.file\:px-3::-webkit-file-upload-button {
  padding-left: .75rem;
  padding-right: .75rem;
}

.file\:px-3::file-selector-button {
  padding-left: .75rem;
  padding-right: .75rem;
}

.file\:py-\[0\.32rem\]::-webkit-file-upload-button {
  padding-top: .32rem;
  padding-bottom: .32rem;
}

.file\:py-\[0\.32rem\]::file-selector-button {
  padding-top: .32rem;
  padding-bottom: .32rem;
}

.file\:text-neutral-700::-webkit-file-upload-button {
  --tw-text-opacity: 1;
  color: rgb(64 64 64 / var(--tw-text-opacity));
}

.file\:text-neutral-700::file-selector-button {
  --tw-text-opacity: 1;
  color: rgb(64 64 64 / var(--tw-text-opacity));
}

.file\:transition::-webkit-file-upload-button {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.file\:transition::file-selector-button {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.file\:duration-150::-webkit-file-upload-button {
  transition-duration: .15s;
}

.file\:duration-150::file-selector-button {
  transition-duration: .15s;
}

.file\:ease-in-out::-webkit-file-upload-button {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.file\:ease-in-out::file-selector-button {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.file\:\[border-inline-end-width\:1px\]::-webkit-file-upload-button {
  border-inline-end-width: 1px;
}

.file\:\[border-inline-end-width\:1px\]::file-selector-button {
  border-inline-end-width: 1px;
}

.file\:\[margin-inline-end\:0\.75rem\]::-webkit-file-upload-button {
  margin-inline-end: .75rem;
}

.file\:\[margin-inline-end\:0\.75rem\]::file-selector-button {
  margin-inline-end: .75rem;
}

.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.hover\:bg-blue-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:bg-green-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(22 101 52 / var(--tw-bg-opacity));
}

.hover\:bg-light-gray:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity));
}

.hover\:bg-red-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(153 27 27 / var(--tw-bg-opacity));
}

.hover\:bg-teal-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(45 212 191 / var(--tw-bg-opacity));
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:shadow-\[0_8px_9px_-4px_rgba\(59\,113\,202\,0\.3\)\,0_4px_18px_0_rgba\(59\,113\,202\,0\.2\)\]:hover {
  --tw-shadow: 0 8px 9px -4px #3b71ca4d, 0 4px 18px 0 #3b71ca33;
  --tw-shadow-colored: 0 8px 9px -4px var(--tw-shadow-color), 0 4px 18px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:drop-shadow-xl:hover {
  --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.hover\:file\:bg-neutral-200::-webkit-file-upload-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.hover\:file\:bg-neutral-200::file-selector-button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.focus\:border-gray-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.focus\:bg-white:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.focus\:text-neutral-700:focus {
  --tw-text-opacity: 1;
  color: rgb(64 64 64 / var(--tw-text-opacity));
}

.focus\:shadow-\[0_0_0_1px\]:focus {
  --tw-shadow: 0 0 0 1px;
  --tw-shadow-colored: 0 0 0 1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-\[0_8px_9px_-4px_rgba\(59\,113\,202\,0\.3\)\,0_4px_18px_0_rgba\(59\,113\,202\,0\.2\)\]:focus {
  --tw-shadow: 0 8px 9px -4px #3b71ca4d, 0 4px 18px 0 #3b71ca33;
  --tw-shadow-colored: 0 8px 9px -4px var(--tw-shadow-color), 0 4px 18px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-blue-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity));
}

.focus\:ring-green-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(134 239 172 / var(--tw-ring-opacity));
}

.focus\:ring-red-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(252 165 165 / var(--tw-ring-opacity));
}

.active\:shadow-\[0_8px_9px_-4px_rgba\(59\,113\,202\,0\.3\)\,0_4px_18px_0_rgba\(59\,113\,202\,0\.2\)\]:active {
  --tw-shadow: 0 8px 9px -4px #3b71ca4d, 0 4px 18px 0 #3b71ca33;
  --tw-shadow-colored: 0 8px 9px -4px var(--tw-shadow-color), 0 4px 18px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.active\:outline-none:active {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

:is(.dark .dark\:border-gray-700) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-green-500) {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-neutral-600) {
  --tw-border-opacity: 1;
  border-color: rgb(82 82 82 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-red-500) {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-b-gray-400) {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(156 163 175 / var(--tw-border-opacity));
}

:is(.dark .dark\:bg-\[\#42464D\]) {
  --tw-bg-opacity: 1;
  background-color: rgb(66 70 77 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-\[\#484b52\]) {
  --tw-bg-opacity: 1;
  background-color: rgb(72 75 82 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-blue-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-700) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-800) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-green-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-secondary-dark-bg) {
  --tw-bg-opacity: 1;
  background-color: rgb(51 55 62 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-transparent) {
  background-color: #0000;
}

:is(.dark .dark\:text-gray-100) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-200) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-300) {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-400) {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-green-500) {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-neutral-200) {
  --tw-text-opacity: 1;
  color: rgb(229 229 229 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-neutral-50) {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-red-500) {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-white) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:-webkit-any(.dark .dark\:file\:bg-neutral-700)::-webkit-file-upload-button {
  --tw-bg-opacity: 1;
  background-color: rgb(64 64 64 / var(--tw-bg-opacity));
}

:is(.dark .dark\:file\:bg-neutral-700)::file-selector-button {
  --tw-bg-opacity: 1;
  background-color: rgb(64 64 64 / var(--tw-bg-opacity));
}

:-webkit-any(.dark .dark\:file\:text-neutral-100)::-webkit-file-upload-button {
  --tw-text-opacity: 1;
  color: rgb(245 245 245 / var(--tw-text-opacity));
}

:is(.dark .dark\:file\:text-neutral-100)::file-selector-button {
  --tw-text-opacity: 1;
  color: rgb(245 245 245 / var(--tw-text-opacity));
}

:is(.dark .dark\:hover\:bg-\[\#42464D\]:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(66 70 77 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-blue-700:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-gray-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-green-500:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-green-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-red-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:text-white:hover) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .dark\:hover\:shadow-2xl:hover) {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

:is(.dark .dark\:focus\:ring-blue-800:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(30 64 175 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-green-800:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(22 101 52 / var(--tw-ring-opacity));
}

:is(.dark .dark\:focus\:ring-red-900:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(127 29 29 / var(--tw-ring-opacity));
}

@media (min-width: 640px) {
  .sm\:rounded-md {
    border-radius: .375rem;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .md\:right-40 {
    right: 10rem;
  }

  .md\:right-52 {
    right: 13rem;
  }

  .md\:m-10 {
    margin: 2.5rem;
  }

  .md\:m-5 {
    margin: 1.25rem;
  }

  .md\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:ml-72 {
    margin-left: 18rem;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-2\/5 {
    width: 40%;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:overflow-hidden {
    overflow: hidden;
  }

  .md\:p-10 {
    padding: 2.5rem;
  }

  .md\:p-5 {
    padding: 1.25rem;
  }

  .md\:hover\:overflow-auto:hover {
    overflow: auto;
  }
}

@media (min-width: 1024px) {
  .lg\:w-2\/3 {
    width: 66.6667%;
  }
}

.thc__navbar {
  padding: 2rem;
}

.thc__navbar-links {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.active {
  color: #83db49;
  font-weight: 600;
}

.thc__navbar-links_logo {
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  display: flex;
}

.thc__navbar-links_logo img {
  width: 50px;
  height: 50px;
}

.thc__navbar-links_brand {
  flex-direction: column;
  align-items: flex-start;
  margin-left: 5px;
  font-weight: 800;
  display: flex;
}

.thc__navbar-links_brand p {
  color: #83db49;
}

.thc__navbar-links_container ul {
  align-items: center;
  list-style-type: none;
  display: flex;
}

.thc__navbar-links_container ul li {
  color: #fff;
  margin-left: 20px;
  font-size: 14px;
}

.thc__navbar-links_container ul li:hover a {
  color: #83db49;
}

.thc__navbar-links-btn {
  border: 1px solid var(--color-btns);
  border-radius: 5px;
  outline: none;
  padding: 5px 20px;
  font-weight: 500;
  transition: background-color .1s ease-in-out;
}

.thc__navbar-links_login {
  border-color: #fff;
}

.thc__navbar-links_join {
  background-color: var(--color-btns);
  border-color: var(--color-btns);
}

.thc__navbar-links_login:hover {
  color: #000;
  background-color: #fff;
}

.thc__navbar-links_join:hover {
  color: var(--color-btns);
  background-color: #0000;
}

.thc__navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
}

.thc__navbar-menu svg {
  cursor: pointer;
}

.thc__navbar-menu_container {
  text-align: right;
  background-color: var(--color-footer);
  min-width: 210px;
  z-index: 2;
  border-radius: 5px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 2rem;
  display: flex;
  position: absolute;
  top: 40px;
  right: 10px;
  box-shadow: 0 0 5px #0003;
}

.thc__navbar-menu_container ul {
  color: #fff;
  list-style-type: none;
}

.thc__navbar-menu_container ul li {
  margin-top: 20px;
}

@media screen and (max-width: 1050px) {
  .thc__navbar-links_container {
    display: none;
  }

  .thc__navbar-menu {
    display: flex;
  }
}

.thc__footer {
  color: #fff;
  background-color: var(--color-footer);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.thc__footer-heading {
  width: 100%;
  text-align: center;
  margin-bottom: 3rem;
}

.thc__footer-heading h3 {
  text-align: center;
  letter-spacing: -.04em;
  font-size: 62px;
  font-style: normal;
  line-height: 75px;
}

.thc__footer-links {
  width: 100%;
  text-align: left;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;
  display: flex;
}

.thc__footer-links div {
  width: 200px;
  margin: 1rem;
}

.thc__footer-links_logo {
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  display: flex;
}

.thc__footer-links_logo img {
  height: 50px;
}

.thc__footer-links_brand {
  flex-direction: column;
  align-items: flex-start;
  margin-left: 5px;
  font-weight: 800;
  display: flex;
}

.thc__footer-links_brand p {
  color: #83db49;
}

.thc__footer-links_div ul {
  justify-content: center;
  align-items: center;
  list-style-type: none;
  display: flex;
}

.thc__footer-links_div h3 {
  text-align: center;
}

.thc__footer-links_div ul li {
  margin: 15px 30px;
}

.thc__footer-copywrite {
  text-align: center;
  width: 100%;
  border-top: 1px solid #6e6e6e;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  padding-top: 2rem;
  display: flex;
}

.thc__footer-copywrite ul {
  align-items: center;
  list-style-type: none;
  display: flex;
}

.thc__footer-copywrite ul li {
  margin: 10px;
}

.thc__footer > p {
  margin-top: 2rem;
}

@media screen and (max-width: 990px) {
  .thc__footer-heading h3 {
    font-size: 3rem;
  }

  .thc__footer-copywrite, .thc__footer-links, .thc__footer-links div {
    justify-content: center;
  }
}

@media screen and (max-width: 550px) {
  .thc__footer-heading h3 {
    font-size: 1.5rem;
  }
}

.thc__loader-container {
  min-height: 80vh;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

.thc__loader {
  justify-content: center;
  align-items: center;
  display: flex;
}

.thc__loader div {
  width: 5px;
  height: 40px;
  background-color: #02003b;
  border-radius: 2.5px;
  margin: 2px;
  animation: 2s cubic-bezier(.19, .57, .3, .98) infinite oddload;
}

.thc__loader div:nth-child(2) {
  animation-delay: .1s;
}

.thc__loader div:nth-child(3) {
  animation-delay: .2s;
}

.thc__loader div:nth-child(4) {
  animation-delay: .3s;
}

.thc__loader div:nth-child(5) {
  animation-delay: .4s;
}

@keyframes oddload {
  0% {
    opacity: .5;
    transform: scaleY(1);
  }

  50% {
    opacity: 1;
    transform: scaleY(.2);
  }

  100% {
    opacity: .5;
    transform: scaleY(1);
  }
}

.thc__pricing-wrapper {
  color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  display: flex;
  overflow-x: hidden;
}

.slider {
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.image {
  width: 1000px;
  height: 600px;
  border-radius: 10px;
}

.right-arrow {
  color: #000;
  z-index: 10;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-size: 3rem;
  position: absolute;
  top: 50%;
  right: 32px;
}

.left-arrow {
  color: #000;
  z-index: 10;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-size: 3rem;
  position: absolute;
  top: 50%;
  left: 32px;
}

.thc__pricing-container {
  width: 90%;
  border-radius: 20px;
  justify-content: space-between;
  padding: 30px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.thc__pricing-left {
  flex: .7;
}

.thc__pricing-left h2 {
  margin-bottom: 10px;
  font-size: 3rem;
}

.thc__pricing-features ul h3 {
  color: #3ec70b;
  font-size: 1.5rem;
}

.thc__pricing-features ul {
  margin: 20px 0;
}

.thc__pricing-features ul li {
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  margin-left: 30px;
  font-size: 1.2rem;
  display: flex;
}

.thc__pricing-right {
  background-color: var(--color-footer);
  border-radius: 20px;
  flex: .3;
}

.thc__pricing-salescard {
  flex-direction: column;
  display: flex;
}

.thc__pricing-details__container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.actual-price, .discount-price {
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  display: flex;
}

.actual-price {
  position: relative;
  overflow: hidden;
}

.actual-price:before {
  content: "";
  width: 100%;
  z-index: 1;
  height: 2px;
  background-color: #fff;
  position: absolute;
}

.discount {
  background-color: #f57b01;
  border-radius: 50px;
  margin: 5px 0;
  padding: 3px 8px;
  font-size: 14px;
  display: inline-block;
}

.thc__pricing-thumbnail {
  height: 200px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
  overflow: hidden;
}

.thc__pricing-thumbnail video {
  height: 100%;
  border-radius: 20px;
}

.thc__pricing-links {
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  display: flex;
}

.thc__pricing-joinus {
  background-color: var(--color-btns);
  text-align: center;
  border-radius: 50px;
  padding: 10px 20px;
}

.thc__pricing-timeline {
  text-align: center;
  color: #000;
  background-color: #fff;
  border-radius: 50px;
  padding: 10px 20px;
}

.thc__pricing-info__footer {
  border-top: 3px solid #000;
  margin-top: 30px;
  padding: 20px;
}

.thc__pricing-info__footer h2 {
  font-size: 20px;
}

.thc__pricing-info__footer ol {
  flex-direction: column;
  gap: 10px;
  margin-left: 30px;
  list-style-type: disc;
  display: flex;
}

@media screen and (max-width: 568px) {
  .thc__pricing-container {
    flex-direction: column-reverse;
  }

  .thc__pricing-details {
    text-align: center;
  }

  .thc__pricing-left h2 {
    text-align: center;
    margin-top: 10px;
    font-size: 2.5rem;
  }
}

.marquee-container {
  overflow: hidden;
}

.thc__marqueeElement-wrapper {
  max-width: 800px;
}

.thc__marqueeElement-container {
  background-color: var(--color-footer);
  border: 1px solid #848383;
  border-radius: 10px;
  align-items: center;
  margin: 0 20px;
  padding: 15px;
  display: flex;
}

.thc__marqueeElement-container img {
  width: 40px;
  height: 40px;
}

.thc__marqueeElement-container p {
  margin-left: 15px;
  font-size: 1.2rem;
}

.thc__features-container_feature {
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding: .5rem;
  display: flex;
}

.thc__features-container_feature-title {
  max-width: 180px;
  flex: 1;
  margin-right: 5px;
}

.thc__features-container_feature-title h3 {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 30px;
}

.thc__features-container_feature-title div {
  width: 48px;
  height: 3px;
  background: var(--gradient-bar);
  margin-bottom: .25rem;
  box-shadow: 0 4px 4px #00000040;
}

.thc__features-container_feature-text {
  max-width: 390px;
  flex: 2;
  display: flex;
}

.thc__features-container_feature-text p {
  color: #fff;
  text-align: left;
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
}

.thc__whatCyberSec {
  background: var(--color-footer);
  background: -o-radial-gradient(circle at 30% -100%, #042c54 25% 85%, #1b78de 100%);
  background: -ms-radial-gradient(circle at 30% -100%, #042c54 25%, #042c54 85%, #1b78de 100%);
  filter: drop-shadow(0 4px 4px #00000040);
  background: radial-gradient(circle at 30% -100%, #042c54 25% 85%, #1b78de 100%);
  flex-direction: column;
  padding: 2rem;
  display: flex;
}

.thc__whatCyberSec-feature {
  display: flex;
}

.thc__whatCyberSec-feature .thc__whatCyberSec-features_container {
  margin: 0;
}

.thc__whatCyberSec-feature .thc__whatCyberSec-features_container-text {
  max-width: 700px;
}

.thc__whatCyberSec-heading h2 {
  max-width: 510px;
  margin: 1.5rem;
  font-size: 34px;
  font-weight: 800;
  line-height: 45px;
}

.thc__whatCyberSec-heading p {
  max-width: 510px;
  color: var(--color-subtext);
  margin: 2rem 1.5rem 0;
  font-size: 24px;
  font-weight: 800;
  line-height: 45px;
}

.thc__whatCyberSec-features_container p {
  text-align: left;
  margin-top: 20px;
  font-size: 1rem;
  font-weight: 500;
}

.thc__whatCyberSec-feature .thc__features-container_feature-text {
  max-width: 700px;
}

.thc__whatCyberSec-features_container {
  flex-wrap: wrap;
  margin-top: 1rem;
  display: flex;
}

.thc__whatCyberSec-features_container .thc__features-container_feature {
  min-width: 210px;
  display: unset;
  flex-direction: column;
  flex: 1;
  margin: 1rem;
}

.thc__whatCyberSec-features_container .thc__features-container_feature-text {
  margin-top: .5rem;
}

@media screen and (max-width: 850px) {
  .thc__whatCyberSec-heading {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
  }

  .thc__whatCyberSec-heading p {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 650px) {
  .thc__whatCyberSec-feature .thc__features-container_feature {
    flex-direction: column;
  }

  .thc__whatCyberSec-feature .thc__features-container_feature-text {
    margin-top: .5rem;
  }

  .thc__whatCyberSec-heading h2 {
    font-size: 28px;
    line-height: 40px;
  }
}

@media screen and (max-width: 350px) {
  .thc__whatCyberSec-features_container .thc__features-container_feature {
    min-width: 100%;
    margin: 1.5rem 0;
  }
}

.thc__ourgoals {
  width: 100%;
  height: 80vh;
  background-image: url("THC_Goals.2acd39a2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: space-between;
  display: flex;
}

.thc__ourgoals-heading {
  flex: 1;
}

.thc__ourgoals-heading h2 {
  font-size: 34px;
  font-weight: 800;
  line-height: 45px;
}

.thc__ourgoals-container {
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.thc__ourgoals-disclaimer {
  max-width: 1000px;
  margin: 50px auto;
}

.thc__ourgoals-disclaimer h3 {
  color: #49ff00;
  text-align: center;
  font-size: 1.8rem;
}

.thc__ourgoals-disclaimer p {
  color: var(--color-text);
}

@media screen and (max-width: 990px) {
  .thc__ourgoals {
    flex-direction: column;
  }

  .thc__ourgoals-heading h2 {
    text-align: center;
    margin: 0 0 2rem;
  }
}

@media screen and (max-width: 550px) {
  .thc__ourgoals-heading h2 {
    font-size: 28px;
    line-height: 38px;
  }

  .thc__ourgoals-container h3 {
    font-size: 18px;
  }

  .thc__ourgoals p {
    font-size: 15px;
  }

  .thc__ourgoals-disclaimer {
    text-align: center;
  }
}

@-moz-keyframes rocket-movement {
  100% {
    -moz-transform: translate(1200px, -600px);
  }
}

@-webkit-keyframes rocket-movement {
  100% {
    -webkit-transform: translate(1200px, -600px);
  }
}

@keyframes rocket-movement {
  100% {
    transform: translate(1200px, -600px);
  }
}

@-moz-keyframes spin-earth {
  100% {
    transition: transform 20s;
    -moz-transform: rotate(-360deg);
  }
}

@-webkit-keyframes spin-earth {
  100% {
    transition: transform 20s;
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes spin-earth {
  100% {
    transition: transform 20s;
    transform: rotate(-360deg);
  }
}

@-moz-keyframes move-astronaut {
  100% {
    -moz-transform: translate(-160px, -160px);
  }
}

@-webkit-keyframes move-astronaut {
  100% {
    -webkit-transform: translate(-160px, -160px);
  }
}

@keyframes move-astronaut {
  100% {
    transform: translate(-160px, -160px);
  }
}

@-moz-keyframes rotate-astronaut {
  100% {
    -moz-transform: rotate(-720deg);
  }
}

@-webkit-keyframes rotate-astronaut {
  100% {
    -webkit-transform: rotate(-720deg);
  }
}

@keyframes rotate-astronaut {
  100% {
    transform: rotate(-720deg);
  }
}

@-moz-keyframes glow-star {
  40% {
    -moz-opacity: .3;
  }

  90%, 100% {
    -moz-opacity: 1;
    -moz-transform: scale(1.2);
  }
}

@-webkit-keyframes glow-star {
  40% {
    -webkit-opacity: .3;
  }

  90%, 100% {
    -webkit-opacity: 1;
    -webkit-transform: scale(1.2);
  }
}

@keyframes glow-star {
  40% {
    -webkit-opacity: .3;
    opacity: .3;
  }

  90%, 100% {
    -webkit-opacity: 1;
    opacity: 1;
    border-radius: 999999px;
    transform: scale(1.2);
  }
}

.spin-earth-on-hover {
  transition: all 200s !important;
  transform: rotate(-3600deg) !important;
}

.bg-purple {
  height: 100vh;
  background: url("http://salehriaz.com/404Page/img/bg_purple.png") 0 0 / cover repeat-x;
  overflow: hidden;
}

.btn-go-home {
  z-index: 200;
  width: 100px;
  color: #fff;
  text-align: center;
  letter-spacing: 2px;
  -o-transition: all .3s ease-in;
  border: 1px solid #ffcb39;
  border-radius: 100px;
  margin: 15px auto;
  padding: 10px 15px;
  font-size: 11px;
  font-weight: 400;
  text-decoration: none;
  transition: all .3s ease-in;
  display: block;
  position: relative;
}

.btn-go-home:hover {
  color: #fff;
  background-color: #ffcb39;
  transform: scale(1.05);
  box-shadow: 0 20px 20px #0000001a;
}

.central-body {
  text-align: center;
  padding: 17% 5% 10%;
}

.objects img {
  z-index: 90;
  pointer-events: none;
}

.object_rocket {
  z-index: 95;
  pointer-events: none;
  animation: 200s linear infinite both rocket-movement;
  position: absolute;
  top: 75%;
  transform: translateX(-50px);
}

.object_earth {
  z-index: 90;
  position: absolute;
  top: 20%;
  left: 15%;
}

.object_moon {
  position: absolute;
  top: 12%;
  left: 25%;
}

.object_astronaut {
  animation: 200s linear infinite alternate both rotate-astronaut;
}

.box_astronaut {
  will-change: transform;
  animation: 50s linear infinite alternate both move-astronaut;
  position: absolute;
  top: 60%;
  right: 20%;
  z-index: 110 !important;
}

.image-404 {
  z-index: 100;
  pointer-events: none;
  position: relative;
}

.stars {
  background: url("http://salehriaz.com/404Page/img/overlay_stars.svg") 0 0 / contain;
}

.glowing_stars .star {
  width: 3px;
  height: 3px;
  opacity: .3;
  will-change: opacity;
  background-color: #fff;
  border-radius: 100%;
  position: absolute;
}

.glowing_stars .star:first-child {
  animation: 2s ease-in-out 1s infinite alternate glow-star;
  top: 80%;
  left: 25%;
}

.glowing_stars .star:nth-child(2) {
  animation: 2s ease-in-out 3s infinite alternate glow-star;
  top: 20%;
  left: 40%;
}

.glowing_stars .star:nth-child(3) {
  animation: 2s ease-in-out 5s infinite alternate glow-star;
  top: 25%;
  left: 25%;
}

.glowing_stars .star:nth-child(4) {
  animation: 2s ease-in-out 7s infinite alternate glow-star;
  top: 75%;
  left: 80%;
}

.glowing_stars .star:nth-child(5) {
  animation: 2s ease-in-out 9s infinite alternate glow-star;
  top: 90%;
  left: 50%;
}

@media only screen and (max-width: 600px) {
  .navbar-links {
    display: none;
  }

  .custom-navbar {
    text-align: center;
  }

  .brand-logo img {
    width: 120px;
  }

  .box_astronaut {
    top: 70%;
  }

  .central-body {
    padding-top: 25%;
  }
}

.thc__loginForm-wrapper {
  background-color: #f4f4f4;
  justify-content: center;
  align-items: center;
  display: flex;
}

.thc__loginForm-container {
  width: 500px;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 50px;
  display: flex;
  box-shadow: 0 0 10px #0003;
}

.thc__loginForm-container h3 {
  text-align: center;
  color: var(--color-text);
  margin-bottom: 30px;
  font-size: 2.5rem;
}

.thc__loginForm-container form {
  width: 100%;
}

.thc__loginBtnsLinks {
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  font-size: 15px;
  font-weight: 300;
  display: flex;
}

.thc__loginForm-container form > div {
  margin: 20px 0;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  text-align: justify;
  font-size: 14px;
}

@media screen and (max-width: 900px) {
  .thc__loginForm-container form {
    flex-direction: column;
  }

  .thc__loginBtnsLinks {
    align-items: center;
    font-size: 14px;
  }

  .thc__loginBtnsLinks a {
    text-align: center;
  }
}

.thc__rules-wrapper {
  flex-direction: column;
  display: flex;
}

.thc__rules-container {
  color: #fff;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  display: flex;
}

.thc__rules-container h3 {
  margin: 1rem 0;
  font-size: 2rem;
}

.thc__rules-container p, .thc__rules-container p:nth-child(2) {
  margin: 1rem 0;
}

.thc__rules-container ul {
  margin: 0 2rem;
  list-style-type: circle;
}

.thc__rules-container li {
  margin: 1rem 0;
  font-size: .9rem;
  list-style-type: circle;
}

.thc__rules-fail {
  text-align: center;
  width: 100%;
}

.thc__rules-policy p {
  align-items: center;
}

.thc__registration-wrapper {
  background-color: #f4f4f4;
  justify-content: center;
  align-items: center;
  display: flex;
}

.thc__registration-container {
  width: 500px;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 50px;
  display: flex;
  box-shadow: 0 0 10px #0003;
}

.thc__registration-container h3 {
  text-align: center;
  color: var(--color-text);
  margin-bottom: 30px;
  font-size: 2.5rem;
}

.payment-qr {
  justify-content: center;
  align-items: center;
  display: flex;
}

.payment-qr img {
  width: 60%;
  height: 60%;
}

.payment-info {
  text-align: center;
}

.thc__registration-container form {
  width: 100%;
}

.thc__registration-container form > div {
  margin: 20px 0;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  text-align: justify;
  font-size: 14px;
}

@media screen and (max-width: 900px) {
  .thc__registration-container form {
    flex-direction: column;
  }
}

.type-writer {
  font-size: 5rem;
}

.type-writer span {
  margin-left: 10px;
  color: #7dce13 !important;
}

.end-cursor:after {
  content: "|";
}

.end-cursor.blinking:after {
  animation: 1s step-start infinite blink;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.gradient__text {
  background: -moz-linear-gradient(var(--gradient-text));
  background: -webkit-linear-gradient(var(--gradient-text));
  background: -o-linear-gradient(var(--gradient-text));
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#8AEE82", endColorstr= "#00FF40", GradientType= 0);
  background: -ms-linear-gradient(var(--gradient-text));
  background: linear-gradient(var(--gradient-text));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.thc__header {
  height: 70vh;
  display: flex;
  overflow: hidden;
}

.thc__header-content {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  margin-right: 5rem;
  display: flex;
}

.thc__header-content span {
  color: gray;
  font-size: 20px;
}

.thc__header-content h1 {
  letter-spacing: 5px;
  font-size: 4.6rem;
  font-weight: 800;
}

.thc__header-content p {
  color: #fff;
  margin-top: 1.5rem;
  font-size: 18px;
  font-style: normal;
  line-height: 27px;
}

.thc__header-img {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 1050px) {
  .thc__header {
    flex-direction: column;
  }

  .thc__header-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  .thc__header-content h1 {
    font-size: 4rem;
    line-height: 60px;
  }

  .thc__header-content p {
    font-size: 1rem;
    line-height: 24px;
  }

  .thc__header-content {
    text-align: center;
    margin: 0 0 3rem;
  }
}

.thc__possibility {
  display: flex;
}

.thc__possibility-img {
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  margin-right: 2rem;
  display: flex;
}

.thc__possibility-img img {
  width: 100%;
  height: 100%;
}

.thc__possibility-content {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: start;
  display: flex;
}

.thc__possibility-content h3 {
  color: #71e5ff;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 30px;
}

.thc__possibility-content h2 {
  font-size: 34px;
  font-weight: 700;
  line-height: 45px;
}

@media screen and (max-width: 950px) {
  .thc__possibility {
    flex-direction: column;
  }

  .thc__possibility-img {
    margin: 1rem 0;
  }

  .thc__possibility-content {
    margin-top: 2rem;
  }

  .thc__possibility-content h3 {
    width: 100%;
    text-align: center;
  }

  .thc__possibility-content h2 {
    text-align: center;
  }
}

.thc__testimonials {
  background-image: url("https://ik.imagekit.io/fq9vykvp2/v617batch2-bb-01-technology.jpg?updatedAt=1687023022297");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: var(--color-footer);
}

.thc__testimonials-heading h2 {
  background: var(--gradient-text);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 20px;
  font-size: 3rem;
  font-weight: 600;
  line-height: 45px;
}

.thc__testimonials-center {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.thc__testimonials-center video {
  height: 100%;
  width: 80%;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: 20px;
}

.carouselInner video::-webkit-media-controls-fullscreen-button {
  display: none;
}

.thc__testimonials-container {
  height: 500px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.thc__testimonials-left {
  height: 100%;
  color: #fff;
  cursor: pointer;
  align-items: center;
  padding: 20px;
  display: flex;
}

.thc__testimonials-center {
  height: 100%;
  flex: .8;
}

.thc__testimonials-right {
  height: 100%;
  color: #fff;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
}

.thc__aboutUs-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.thc__aboutUs-heading h2 {
  background: var(--gradient-text);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
  font-size: 2rem;
  line-height: 45px;
}

.thc__aboutUs-content p {
  color: #fff;
  max-width: 950px;
  margin-bottom: 20px;
  font-size: 1.1rem;
  line-height: 30px;
}

@media screen and (max-width: 768px) {
  .thc__aboutUs-content p {
    text-align: center;
    font-size: 1rem;
  }
}

* {
  scroll-behavior: smooth;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Nunito, sans-serif;
}

.thc__landingPage {
  background-color: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__bg {
  background: -o-radial-gradient(circle at 3% 25%, #002853 0%, #040c18 100%);
  background: -ms-radial-gradient(circle at 3% 25%, #002853 0%, #040c18 100%);
  background: radial-gradient(circle at 3% 25%, #002853 0%, #040c18 100%);
}

.gradient__text {
  background: var(--gradient-text);
  -webkit-background-clip: text;
  background-clip: text;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
  animation: .4s cubic-bezier(.39, .575, .565, 1) both scale-up-center;
}

@keyframes scale-up-center {
  0% {
    transform: scale(.5);
  }

  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }
}

.thc__verifyEmail-wrapper {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.thc__verifyEmail-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  display: flex;
  box-shadow: 0 0 10px #0003;
}

.thc__verifyEmail-container > * {
  color: #fff;
}

.thc__forgotPassword-wrapper {
  height: 100%;
  background-color: #ddd;
  justify-content: center;
  align-items: center;
  display: flex;
}

.thc__forgotPassword-container {
  height: 300px;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 30px;
  display: flex;
  box-shadow: 0 0 10px #0003;
}

.thc__forgotPassword-container h1 {
  color: #00b600;
}

.thc__forgotPassword-container p {
  font-size: 15px;
}

.thc__forgotPassword-container div {
  width: 100%;
}

.thc__forgotPassword-container h2 {
  color: var(--color-text);
}

.thc__forgotPassword-container button {
  margin-top: 10px;
}

.thc__passwordreset-wrapper {
  height: 100%;
  background-color: #ddd;
  justify-content: center;
  align-items: center;
  display: flex;
}

.thc__passwordreset-container {
  width: 400px;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 30px;
  display: flex;
  box-shadow: 0 0 10px #0003;
}

.thc__passwordreset-header {
  text-align: center;
}

.thc__passwordreset-header h2 {
  color: var(--color-text);
}

.thc__passwordreset-container button {
  float: right;
  margin-top: 20px;
}

.thc__courseInfo-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.thc__courseInfo-wrapper h2 {
  color: #fff;
}

.month-select select {
  width: 200px;
  background-color: var(--color-footer);
  color: #fff;
  border-radius: 8px;
  padding: 10px;
  font-family: inherit;
}

.thc__courseInfo-container {
  width: 900px;
  color: #fff;
  flex-direction: column;
  margin: 40px 0;
  display: flex;
  position: relative;
}

.thc__courseInfo-container:after {
  content: "";
  background-color: var(--color-btns);
  width: 4px;
  height: 100%;
  position: absolute;
  left: calc(50% - 2px);
}

@media screen and (max-width: 768px) {
  .thc__courseInfo-container {
    width: 100%;
  }
}

.thc__timeline-item {
  width: 50%;
  justify-content: flex-end;
  margin: 10px 0;
  padding-right: 30px;
  display: flex;
}

.thc__timeline-item:nth-child(odd) {
  justify-content: flex-start;
  align-self: flex-end;
  padding-left: 30px;
  padding-right: 0;
}

.thc__timeline-item-content {
  max-width: 500px;
  background-color: var(--color-footer);
  text-align: right;
  color: #fff;
  border-radius: 5px;
  flex-direction: column;
  align-items: flex-end;
  padding: 20px;
  display: flex;
  position: relative;
  box-shadow: 0 0 5px #0000004d;
}

.thc__timeline-item:nth-child(odd) .thc__timeline-item-content {
  text-align: left;
  align-items: flex-start;
}

.thc__timeline-item-content:after {
  content: "";
  background-color: var(--color-btns);
  width: 15px;
  height: 15px;
  z-index: -1;
  position: absolute;
  top: calc(50% - 7.5px);
  right: -7.5px;
  transform: rotate(45deg);
  box-shadow: 1px -1px 1px #0003;
}

.thc__timeline-item:nth-child(odd) .thc__timeline-item-content:after {
  left: -7.5px;
  right: auto;
  box-shadow: -1px 1px 1px #0003;
}

.thc__timeline-item-content .tag {
  color: #000;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 7px;
  left: 7px;
}

.thc__timeline-item:nth-child(odd) .thc__timeline-item-content .tag {
  top: 7px;
  left: auto;
  right: 7px;
}

.thc__timeline-item-content .time {
  color: #777;
  font-size: 12px;
}

.thc__timeline-item-content p {
  max-width: 250px;
  margin: 15px 0;
  font-size: 1rem;
  line-height: 24px;
}

.thc__timeline-item-content a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
}

.thc__timeline-item-content .circle {
  background-color: var(--color-bg);
  width: 20px;
  height: 20px;
  border: 3px solid var(--color-btns);
  z-index: 1;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 10px);
  right: -40px;
}

.thc__timeline-item:nth-child(odd) .thc__timeline-item-content .circle {
  left: -40px;
  right: auto;
}

@media screen and (max-width: 768px) {
  .thc__timeline-item-content .tag {
    padding: 3px;
    font-size: 10px;
  }

  .thc__timeline-item-content p {
    font-size: 14px;
  }
}

.thc__registrationConfirmation-wrapper {
  height: 50vh;
}

.thc__gallery h2 {
  color: #3ec70b;
  text-align: center;
  margin-bottom: 20px;
  font-size: 3rem;
  font-weight: 600;
}

.gallery-container {
  opacity: 0;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(150px, auto);
  gap: 20px;
  display: grid;
}

.thc__gallery-category {
  text-align: center;
  color: #3ec70b;
  margin: 40px 0 20px;
  font-size: 2rem;
}

.gallery-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0 2px 4px #0006;
}

@media (max-width: 768px) {
  .gallery-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

.gallery-container.slide-in {
  animation: .8s ease-out forwards slideIn;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.gallery-container.slide-in img {
  animation: .8s ease-out forwards slideIn;
}

.gallery-container.slide-in img:first-child {
  animation-delay: 0s;
}

.gallery-container.slide-in img:nth-child(2) {
  animation-delay: .2s;
}

.gallery-container.slide-in img:nth-child(3) {
  animation-delay: .4s;
}

.gallery-container.slide-in img:nth-child(4) {
  animation-delay: .6s;
}

.gallery-container.slide-in img:nth-child(5) {
  animation-delay: .8s;
}

.ant-modal-content {
  background-color: var(--color-bg) !important;
}

.ant-modal-content .ant-modal-close {
  color: #fff !important;
  top: 21px !important;
  right: 5px !important;
}

.ant-modal-content .ant-modal-close .ant-modal-close-x {
  font-size: 30px !important;
}

.thc__paymentPage-wrapper {
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
}

.thc__paymentPage-container {
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.thc_paymentPage-left {
  flex-direction: column;
  flex: .5;
  gap: 20px;
  display: flex;
}

.thc_paymentPage-left__title {
  margin-right: 30px;
  display: flex;
}

.thc_courseThumbnail video {
  width: 200px;
  object-fit: contain;
  border-radius: 10px;
}

.thc_paymentPage-left__title h3 {
  margin: 0 20px;
  font-size: 2.5rem;
}

.thc__payment-features ul h3 {
  color: #3ec70b;
  font-size: 1.5rem;
}

.thc__payment-features ul {
  margin: 20px 0;
}

.thc__payment-features ul li {
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  margin-left: 30px;
  font-size: 1.2rem;
  display: flex;
}

.thc_payment-userDetails_form-control {
  flex-direction: column;
  margin: 20px 0;
  display: flex;
}

.thc_payment-userDetails_form-control input {
  width: 60%;
  background-color: #0000;
  border: 1px solid #383838;
  border-radius: 10px;
  outline: none;
  padding: 10px;
  display: inline-block;
}

.thc__paymentPage-right {
  width: 200px;
  min-width: 400px;
  border: 1px solid #383838;
  border-radius: 20px;
  flex-direction: column;
  flex: .5;
  gap: 20px;
  padding: 30px;
  display: flex;
}

.thc__paymentPage-right h3 {
  font-size: 2rem;
}

.thc__paymentPage-right .details {
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  display: flex;
}

.thc__paymentPage-right .details p {
  font-size: 1.3rem;
}

.payment_btn {
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  display: flex;
}

.payment_btn button {
  border: 1px solid var(--color-btns);
  text-transform: uppercase;
  border-radius: 50px;
  outline: none;
  padding: 20px 50px;
}

.payment_btn button:hover {
  background-color: var(--color-btns);
  color: #fff;
}

.thc__paymentStatusCheck {
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
}

.thc__paymentStatusCheck h3 {
  font-size: 1.5rem;
}

.thc__paymentFailure {
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
}

.thc__paymentFailure h2 {
  color: red;
  margin-bottom: 10px;
  font-size: 2.5rem;
}

strong {
  color: var(--color-text);
}

.thc__paymentFailure p {
  margin-bottom: 15px;
  font-size: 16px;
}

.thc__termsConditions h2 {
  margin-bottom: 10px;
  font-size: 2rem;
}

.section__padding {
  color: #fff;
  padding: 4rem 10rem;
}

.thc__termsConditions .terms {
  margin: 2rem 0;
}

.thc__termsConditions .terms .mail {
  padding-left: 5px;
  text-decoration: underline;
}

.thc__termsConditions .terms h3 {
  font-size: 1.2rem;
}

.thc__termsConditions .terms ul {
  padding-left: 2rem;
  list-style-type: circle;
}

.thc__termsConditions .terms ul li {
  margin-top: 5px;
}

/*# sourceMappingURL=index.407449ef.css.map */
