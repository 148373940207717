.thc__navbar {
    padding: 2rem;
}

.thc__navbar-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
}

.active {
    font-weight: 600;
    color: #83DB49;
}

.thc__navbar-links_logo {
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.thc__navbar-links_logo img {
    width: 50px;
    height: 50px;
}

.thc__navbar-links_brand {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-weight: 800;
    margin-left: 5px;
}

.thc__navbar-links_brand p {
    color: #83DB49;
}

.thc__navbar-links_container ul {
    display: flex;
    list-style-type: none;
    align-items: center;
}

.thc__navbar-links_container ul li {
    color: white;
    margin-left: 20px;
    font-size: 14px;
}

.thc__navbar-links_container ul li:hover a {
    /* font-weight: 600; */
    color: #83DB49;
}

.thc__navbar-links-btn {
    border: 1px solid var(--color-btns);
    outline: none;
    border-radius: 5px;
    padding: 5px 20px;
    font-weight: 500;
    transition: background-color 0.1s ease-in-out;
}

.thc__navbar-links_login {
    border-color: #fff;
}

.thc__navbar-links_join {
    background-color: var(--color-btns);
    border-color: var(--color-btns);
}


.thc__navbar-links_login:hover {
    background-color: #fff;
    color: black;
}

.thc__navbar-links_join:hover {
    background-color: transparent;
    color: var(--color-btns);
}

.thc__navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.thc__navbar-menu svg {
    cursor: pointer;
}

.thc__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: right;
    background-color: var(--color-footer);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 10px;
    border-radius: 5px;
    min-width: 210px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
    z-index: 2;
}

.thc__navbar-menu_container ul {
    list-style-type: none;
    color: white;
}

.thc__navbar-menu_container ul li {
    margin-top: 20px;
}


@media screen and (max-width:1050px) {

    .thc__navbar-links_container {
        display: none;
    }

    .thc__navbar-menu {
        display: flex;
    }
}