.thc__features-container_feature {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 0.5rem;
}



.thc__features-container_feature-title {
    flex: 1;
    max-width: 180px;
    margin-right: 5px;
}

.thc__features-container_feature-title h3 {
    font-weight: 600;
    line-height: 30px;
    font-size: 1.2rem;
    color: #ffffff;
}

.thc__features-container_feature-title div {
    width: 48px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.thc__features-container_feature-text {
    flex: 2;
    max-width: 390px;
    display: flex;
}

.thc__features-container_feature-text p {
    font-weight: 500;
    font-size: 1rem;
    line-height: 24px;
    color: white;
    text-align: left;
}